import React from "react";
import { useStyles, Text } from "@chakra-ui/react";

const Paragraph = (props) => {
  try {
    const styles = useStyles();
    return <Text margin="1em" __css={styles.item} {...props} />;
  } catch {
    return <Text marginTop="1em" {...props} />;
  }
};

export default Paragraph;
